import * as React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

// components
import Layout from '../components/layout'

// icons
import { IconArrowRight } from '../components/icons';
import { Trans } from 'react-i18next';

// seo
const seo = {
  title: 'seo.products.title',
  description: 'seo.products.description'
}

const ProdutosPage = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  return (
    <>
      <Layout seo={seo}>
        <section className="pageHeader">
          <div className="container">
            <h1><Trans i18nKey={seo.title} /></h1>
            <h2 className="title title--display"><Trans i18nKey='products.page.headline' /></h2>
          </div>
        </section>

        <div className="container space-y-md">
          {edges.map(edge => {
            const { frontmatter } = edge.node;
            const path = frontmatter.path.split('/')[1];

            return (
              <div key={frontmatter.path} className="card card--horizontal card--border card--rounded">
                <div key={frontmatter.path} className="card__body">
                  <h2>
                    <Trans i18nKey={`products.${path}.title`} />
                  </h2>
                  <p><Trans i18nKey={`products.${path}.description`} /></p>
                  <Link className="card__action button button--pink button--nude" to={path}>
                    <span>
                      <Trans i18nKey={`products.btnlabel`} />
                      <Trans i18nKey={`products.${path}.title`} />
                    </span>
                    <IconArrowRight className="icon" />
                  </Link>
                </div>

                <Img className="image card__image" fluid={frontmatter.featuredImage.childImageSharp.fluid} />
              </div>
            )
          })}
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  query ($language: String!) {
    allMarkdownRemark(
      sort: {fields: frontmatter___title, order: DESC}
      filter: {frontmatter: {
        posttype: {eq: "produto"}, 
      locale: {eq: $language}
    }}
    ) {
      edges {
        node {
          id
          frontmatter {
            posttype
            path
            title
            description
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }

    locales: allLocale(
      filter: { ns: { in: ["produtos", "layout", "header", "footer"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default ProdutosPage
